import React from "react";
import { TOKEN_POST, TOKEN_VALIDATE_POST, USER_GET } from "./api";
import { useNavigate } from "react-router-dom";

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
    const [data, setData] = React.useState(null);
    const [login, setLogin] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const navigate = useNavigate();

    const userLogout = React.useCallback(
        async function () {
            setData(null);
            setError(null);
            setLoading(false);
            setLogin(false);
            window.localStorage.removeItem("token");
            navigate("/login");
        },
        [navigate]
    );

    async function getUser(token) {
        const { url, options } = USER_GET(token);
        const response = await fetch(url, options);
        const json = await response.json();
        setData(json);
        setLogin(true);
        //console.log(json);
    }

    async function userLogin(username, password) {
        try {
            setError(null);
            setLoading(true);
            const { url, options } = TOKEN_POST({ username, password });
            const response = await fetch(url, options);
            //console.log(response);
            if (!response.ok) {
                throw new Error(`Error ${response.statusText}`);
                //throw new Error( `Error: Usuário inválido.` );
            }

            const json = await response.json();
            window.localStorage.setItem("token", json.token);
            await getUser(json.token);

            navigate("/account");
        } catch (exception) {
            setError(exception.message);
            setLogin(false);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        async function autoLogin() {
            const token = window.localStorage.getItem("token");

            if (token) {
                try {
                    setError(null);
                    setLoading(true);

                    const { url, options } = TOKEN_VALIDATE_POST(token);
                    const response = await fetch(url, options);

                    if (!response.ok) {
                        throw new Error("Invalid Token");
                    }

                    await getUser(token);
                } catch (exception) {
                    userLogout();
                } finally {
                    setLoading(false);
                }
            } else {
                setLogin(false);
            }
        }

        autoLogin();
    }, [userLogout]);

    return (
        <UserContext.Provider
            value={{ userLogin, userLogout, data, error, loading, login }}
        >
            {children}
        </UserContext.Provider>
    );
};
