import React from "react";

const Head = (props) => {
    React.useEffect(() => {
        document.title =
            props.title.charAt(0).toUpperCase() +
            props.title.slice(1).toLowerCase() +
            " | Wapuus";
        document
            .querySelector("meta[name='description']")
            .setAttribute("content", props.description || "");
    }, [props]);

    return <></>;
};

export default Head;
