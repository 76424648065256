import React from "react";

const useFetch = () => {
    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const request = React.useCallback(async (url, options) => {
        let response;
        let json;

        try {
            setError(null);
            setLoading(true);
            response = await fetch(url, options);
            //console.log("response: ", response);
            json = await response.json();
            //console.log('response.json(): ', json);
            if (response.ok === false) {
                throw new Error(json.message);
            }
        } catch (exception) {
            json = null;
            //console.log( 'exception.message: ', exception.message );
            setError(exception.message);
        } finally {
            setData(json);
            setLoading(false);
            return { response, json };
        }
    }, []);

    return {
        data,
        loading,
        error,
        request,
    };
};

export default useFetch;
